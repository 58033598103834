<template>
  <div>
    <div>
      <textarea-autosize
        v-if="type !== 'a' && !isAdditionalContent"
        v-model="originData"
        :class="className"
        :dir="dir"
        :disabled="true"
        :rows="1"
        style="border: none; background: transparent; width: 190px;color: #000"
      />
      <!-- <label v-if="type == 'label' && !isAdditionalContent" :style="customStyle"> {{ originData }}</label> -->
      <label v-if="type == 'label' && isAdditionalContent" :style="customStyle">
        <div class="additional-container">
          <check-box-editor
            v-model="checkboxValue"
            :is-edit="isEdit"
            @transfer="transfer($event)"
          />
          <span>{{ checkboxLabelText }}</span>
        </div>
      </label>
      <!-- <h1 v-if="type == 'h1'" :style="cusStyle">
        {{ originData }}
      </h1>
      <h2 v-if="type == 'h2'" :style="cusStyle">
        {{ originData }}
      </h2>
      <h3 v-if="type == 'h3'" :style="cusStyle">
        {{ originData }}
      </h3>
      <h4 v-if="type == 'h4'" :style="cusStyle">
        {{ originData }}
      </h4>
      <h5 v-if="type == 'h5'" :style="cusStyle">
        {{ originData }}
      </h5>
      <p v-if="type == 'p'" :style="cusStyle">
        {{ originData }}
      </p> -->
      <a
        v-if="type == 'a'"
        :href="'https://' + originData"
        :style="cusStyle"
        target="_blank"
      >
        {{ originData }}
      </a>
    </div>
    <div v-if="isEdit">
      <div v-if="editType == 'text'">
        <div v-if="!isAdditionalContent" class="main">
          <textarea-autosize
            v-model="editValue"
            :dir="dir"
            class="form-control"
            placeholder="Add description"
            :maxlength="validateMaxValue"
            @focusout.native="focusOut()"
            @input="handleInput"
          />
          <!-- 
            https://stackoverflow.com/questions/42067208/how-can-i-add-text-to-a-text-area-at-the-bottom-right-of-the-text-area
          -->
          <span>{{ editValue.length }}/{{ validateMaxValue }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBoxEditor from "../questionnaire/CheckBoxEditor";
export default {
  name: "EditorPrescription",
  components: {CheckBoxEditor},
  props: {
    value: {
      type: [String, Number, Boolean],
      default: "0",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    editType: {
      type: String,
      default: "text",
    },
    validateMaxValue: {
      type: Number,
      default: 500,
    },
    type: {
      type: String,
      default: "label",
    },
    cusStyle: {
      type: String,
      default: "color: black;word-break: break-word",
    },
  },
  data() {
    const {code, name} = this.$store.state.survey.language;
    const isArabic = code === "ar" && name === "Arabic";
    return {
      dir: isArabic ? "rtl" : "ltr",
      customStyle: isArabic ? this.cusStyle+";text-align: end;width: 190px" : this.cusStyle+";text-align: start",
      //mutateValue: this.value,
      editValue: this.value,
      originData: this.value,
      isAdditionalContent: false,
      checkboxValue: false,
      checkboxLabelText: null
    };
  },
  computed: {
    applyValue: {
      get: function () {
        if (this.isAdditionalContent) {
            const {text} = this.extractValue(this.mutateValue)
            return text
        } else return this.mutateValue;
      },
      set: function (newValue) {
          if (this.isAdditionalContent) {
              this.mutateValue = `${newValue}{${this.checkboxValue.toString()}}${this.checkboxLabelText}`
          } else {
              this.mutateValue = newValue;
          }
          this.presentValue = this.createPresentValue(newValue)
      },
    },
  },
  watch: {
    checkboxValue: function () {
      if (this.isAdditionalContent) {
        const {text} = this.extractValue(this.mutateValue, !this.checkboxValue)
        this.mutateValue = `${text}{${this.checkboxValue.toString()}}${this.checkboxLabelText}`
        this.focusOut()
      }
    }
  },
  mounted() {
    this.mutateValue = this.value
    if (this.checkCheckboxSegment()) {
      const {text} = this.extractValue(this.value)
      this.presentValue = this.createPresentValue(text)
    } else {
      this.presentValue = this.createPresentValue(this.value)
    }
  },
  methods: {
    transfer(event) {
      this.checkboxValue = event;
      if (this.checkboxValue) {
          this.mutateValue = '{true} MANDATORY';
      } else {
          this.mutateValue = '{false} MANDATORY';
      }
        this.$emit("input", this.isAdditionalContent ? this.mutateValue : this.applyValue);
    },
    focusOut() {
        // if (this.mutateValue.includes('{true}')) {
        //     this.mutateValue.replace('{true} MANDATORY,', '');
        //     this.mutateValue = this.mutateValue.replaceAll('{true} MANDATORY,', '');
        //     this.mutateValue = '{true} MANDATORY,' + this.mutateValue;
        // } else if (this.mutateValue.includes('{false}')) {
        //     this.mutateValue.replace('{false} MANDATORY,', '');
        //     this.mutateValue = this.mutateValue.replaceAll('{false} MANDATORY,', '');
        //     this.mutateValue = '{false} MANDATORY,' + this.mutateValue;
        // }
        // this.$emit("input", this.isAdditionalContent ? this.mutateValue : this.applyValue);
    },
    createPresentValue(text) {
      return /\r|\n/.test(text) ? text.split('\n') : text
    },
    extractValue(text, bool = null) {
      const splitSeg = bool !== null ? bool : this.checkboxValue.toString()
      const splitValue = text.split(`{${splitSeg}}`)
      return {
        text: splitValue[0],
        label: splitValue[1] || ''
      }
    },
    handleInput() {
      if (/[^\s]+/.test(this.editValue)) {
        this.$emit("input", this.editValue);
      } else {
        this.$emit("input", this.originData);
      }
      //   this.$nextTick(() => {});
    },

    checkMaxValue($event) {
      if (this.editValue.length > this.validateMaxValue) {
        this.editValue = this.editValue.substring(0, this.validateMaxValue);
        $event.preventDefault();
        return;
      }
    },

    onPaste($event) {
      const value = $event.clipboardData.getData("text");
      if (
        value.length >= this.validateMaxValue ||
        this.editValue.length + value.length >= this.validateMaxValue
      ) {
        this.editValue = this.editValue.substring(0, this.validateMaxValue + 1);
        $event.preventDefault();
        return;
      }
    },
    checkCheckboxSegment() {
      if (!this.value.includes('{true}') && !this.value.includes('{false}')) {
          return false;
      }
      this.isAdditionalContent = true;
      this.checkboxValue = this.value.includes('{true}');
      const {label} = this.extractValue(this.value);
      this.checkboxLabelText = label;
      return true;
    }
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
  background-color: transparent;
  color: #321fdb !important;
}
textarea {
  resize: none;
}

label {
  white-space: pre-wrap;
}

.main {
  position: relative;
  display: inline-block;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  background-color: #fff;
}

.main textarea {
  border: none!important;
  margin-bottom: 20px;
}

.main textarea:focus, .main textarea:active {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.main span {
  color: #768192;
  position: absolute;
  bottom: .375rem;
  right: .75rem;
  font-size: 10px;
}
.additional-container {
  display: flex;
}
</style>
