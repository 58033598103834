<template>
  <div>
    <div v-if="isShowValue">
      <label> {{ value }}</label>
    </div>
    <div v-if="isEdit">
      <input
        v-model="editValue"
        type="number"
        class="form-control"
        :max="max"
        :min="min"
        :step="step"
        placeholder="leave blank if NA" 
        @focusout="focusOut($event)"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty, isFloatNumber } from "../../common/CommonUtils";
export default {
  name: "EditorNumber",
  components: {},
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    min: {
      type: [Number, String],
      default: null,
    },
    max: {
      type: [Number, String],
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    step: {
      type: [String, Number],
      default: 1,
    },
    isShowValue: {
      type: Boolean,
      default: true,
    },
    isInteger: {
      type: Boolean,
      default: false,
    },
    isIndex: {
        type: Number,
        default: 0,
    },
    placeholderText:{
      type: String,
      default: "",
    },
    isStart: {
      type: Boolean,
      default: false,
    },
    isEnd: {
      type: Boolean,
      default: false,
    },
    start: {
      type: Number,
      default: null,
    },
    end: {
      type: Number,
      default: null,
    },
  },
  data() {
    // eslint-disable-next-line vue/no-mutating-props
    this.value = isEmpty(this.value) ? "" : this.value;
    return {
      editValue: this.value,
    };
  },
  computed: {},
  mounted() {},
  updated() {
    // eslint-disable-next-line vue/no-mutating-props
    this.value = isEmpty(this.value) ? "" : this.value;
  },

  methods: {
    focusOut($event) {
      this.checkMaxMinValue($event)
      if (this.isIndex == 2 || this.isIndex == 3 || this.isIndex == 6) {
        let tmp = this.editValue.toString().split(".")[0];
        this.editValue = tmp;
      }
      this.$emit("input", this.editValue);
    },
    checkMaxMinValue($event) {
      if (this.isInteger && isFloatNumber(this.editValue)) {
        this.editValue = Math.round(+this.editValue);
        $event.preventDefault();
        return;
      }
      if(this.isStart && (this.editValue >= this.end || this.step > this.end - Number(this.editValue))) {
        this.editValue = Number(this.end - this.step);
        $event.preventDefault();
        return;
      }
      if(this.isEnd && this.editValue <= this.start) {
        this.editValue = Number(this.start + this.step);
        $event.preventDefault();
        return;
      }
      if (+this.editValue > +this.max && this.max !== null) {
        this.editValue = this.max;
        $event.preventDefault();
        return;
      }
      if (+this.editValue < +this.min && this.min !== null && this.editValue !== "") {
        this.editValue = this.min;
        $event.preventDefault();
        return;
      }
      
    },
    handleInput() {
      this.$emit("input", this.editValue);
    },
  },
};
</script>
<style scoped>
label {
  white-space: pre-wrap;
}
</style>
