<template>
  <div>
    <b-row>
      <b-col>
        <!-- -->
        <b-row>
          <b-col>
            <div style="margin-top: 39px">
              <p>This is used for language transition only</p>
              <p>
                <strong>Note:</strong> Changing the context of the question or
                leaving a translation empty <strong>does not</strong> change the
                logic of the questionnaire
              </p>
            </div>
          </b-col>
        </b-row>
        <div style="height: 52px"></div>
        <!-- -->
        <b-row>
          <b-col>
            <!--- --->
            <b-table-simple
              v-if="prescription && prescription.length > 0"
              id="table-edit"
              hover
              small
              caption-top
              responsive
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th
                    v-for="data in header"
                    :key="data.title"
                    :class="isEditContent"
                    :style="data.style"
                  >
                    {{ data.title }}
                  </b-th>
                </b-tr>
              </b-thead>
              <!---- body --------------------------------------------- -->
              <b-tbody>
                <!---- previous design--->
                <b-tr
                  v-for="(option, index) in prescription[0].options"
                  :key="index + 'tr1'"
                >
                  <b-td
                    v-if="index === 0"
                    :rowspan="prescription[0].options.length"
                  >
                    <EditorPrescription
                      :key="
                        prescription[0].prescription[0].isEdit +
                          index +
                          '2d1d12dasd1aqw2f212'
                      "
                      v-model="prescription[0].prescription[0].data"
                      :cus-style="curStyle"
                      :is-edit="prescription[0].prescription[0].isEdit"
                      :validate-max-value="23"
                    />
                  </b-td>

                  <b-td
                    v-if="index === 0"
                    :rowspan="prescription[0].options.length"
                  />

                  <b-td>
                    <EditorPrescription
                      :key="option.isEdit + index + '2d1dhgfgsdaqw2f212'"
                      v-model="option.data"
                      :cus-style="curStyle"
                      :is-edit="option.isEdit"
                      :validate-max-value="index === 5 ? 8 : 29"
                    />
                  </b-td>
                </b-tr>
                <!---- 'Right(OD)--->
                <b-tr
                  v-for="(option, index) in prescription[1].views"
                  :key="index + 'tr2'"
                >
                  <b-td :class="{ 'prescription-td': index > 0 }">
                    <EditorPrescription
                      :key="
                        prescription[1].prescription[index].isEdit +
                          index +
                          '2d1243d12dasdaqw2f212'
                      "
                      v-model="prescription[1].prescription[index].data"
                      :cus-style="curStyle"
                      :is-edit="prescription[1].prescription[index].isEdit"
                      :validate-max-value="
                        index === 0 || index === 1 ? 14
                        : index === 2 ? 9 : index === 3 ? 20 : 8
                      "
                    />
                  </b-td>
                  <!---- ---->
                  <b-td v-if="index === 2">
                    <div>
                      <EditorPrescription
                        :key="
                          prescription[1].units[2].isEdit +
                            index +
                            '2d341d12dasdaqw2f212'
                        "
                        v-model="prescription[1].units[2].data"
                        :cus-style="curStyle"
                        :is-edit="prescription[1].units[2].isEdit"
                        :validate-max-value="5"
                      />
                    </div>
                  </b-td>
                  <b-td v-if="index === 3" class="prescription-td">
                    <div>
                      <EditorPrescription
                        :key="
                          prescription[1].units[3].isEdit +
                            index +
                            '2d341d12dasdaqw2f212'
                        "
                        v-model="prescription[1].units[3].data"
                        :cus-style="curStyle"
                        :is-edit="prescription[1].units[3].isEdit"
                        :validate-max-value="4"
                      />
                    </div>
                  </b-td>
                  <b-td
                    v-else-if="index === 4"
                    class="prescription-td"
                    :rowspan="3"
                  />
                  <b-td v-else-if="index === 7" class="prescription-td">
                    <div></div>
                  </b-td>
                  <!---- ---->
                  <b-td>
                    <div v-if="(index === 2 || index === 3 || index === 4 || index === 5 || index === 7) && !prescription[1].prescription[index].isEdit" style="font-weight: bold">
                      <label> Always Mandatory</label>
                    </div>
                    <div v-if="index === 2 || index === 3">
                      <div>
                        <!-- AGE -->
                        <EditorNumberAge
                          v-if="index === 2"
                          :id="random()"
                          :key="option.isEdit + index + '2d341d1234dasdaqwe2111f21223s'"
                          v-model="option.data"
                          :cus-style="curStyle"
                          :is-edit="option.isEdit"
                          :is-index="index"
                        />
                        <!-- READING DISTANCE -->
                        <EditorNumberRange
                          v-if="index === 3"
                          :id="random()"
                          :key="option.isEdit + index + '2d341d1234dasdaqwe2111f212dfdfdfdf232'"
                          v-model="option.data"
                          :cus-style="curStyle"
                          :is-edit="option.isEdit"
                          :is-index="index"
                          :is-mandatory="checkboxValue"
                          :default-data="prescription[1].defaults[3].data"
                        />
                        <b-tr v-if="index === 3 && !option.isEdit">
                          <b-td style="border: none;">
                            <label style="padding-top: 7px;">Default value</label>
                          </b-td>
                          <b-td style="border: none;">
                            <input
                              v-model="text3"
                              type="text"
                              class="form-control"
                              disabled
                            />
                          </b-td>
                        </b-tr>
                        <span v-if="index === 3" class="font-weight-bold" :style="'fontSize: 13px'">
                          Whole numbers only, within the start and end numbers.
                        </span>
                      </div>
                    </div>
                    <div v-else>
                      <!-- AXIS -->
                      <div v-if="index == 6" class="additional-container">
                        <check-box-editor
                          v-model="checkboxValue"
                          :is-edit="prescription[1].options[6].isEdit"
                          @transfer="transfer($event)"
                        />
                        <span>{{ checkboxLabelText }}</span>
                      </div>
                      <EditorNumberRange
                        :id="random()"
                        :key="
                          option.isEdit + index + '2d341d1234dasdaqwe2111f212'
                        "
                        v-model="option.data"
                        :cus-style="curStyle"
                        :is-edit="option.isEdit"
                        :is-index="index"
                        :is-mandatory="checkboxValue"
                        :default-data="prescription[1].defaults[index].data"
                      />
                    </div>
                    <!-- SPHERE -->
                    <b-tr v-if="index === 4 && !option.isEdit">
                      <b-td style="border: none;">
                        <label style="padding-top: 7px;">Default value</label>
                      </b-td>
                      <b-td style="border: none;">
                        <input
                          v-model="text4"
                          type="text"
                          class="form-control"
                          disabled
                        />
                      </b-td>
                    </b-tr>
                    <!-- CYLINDER -->
                    <b-tr v-if="index === 5 && !option.isEdit">
                      <b-td style="border: none;">
                        <label style="padding-top: 7px;">Default value</label>
                      </b-td>
                      <b-td style="border: none;">
                        <input
                          v-model="text5"
                          type="text"
                          class="form-control"
                          disabled
                        />
                      </b-td>
                    </b-tr>
                    <!-- AXIS -->
                    <b-tr v-if="index === 6 && !option.isEdit">
                      <b-td style="border: none;">
                        <label style="padding-top: 7px;">Default value</label>
                      </b-td>
                      <b-td style="border: none;">
                        <input
                          v-model="prescription[1].defaults[index].data"
                          type="text"
                          class="form-control"
                          disabled
                        />
                      </b-td>
                    </b-tr>
                    <!-- ADDITION -->
                    <b-tr v-if="index === 7 && !option.isEdit">
                      <b-td style="border: none;">
                        <label style="padding-top: 7px;">Default value</label>
                      </b-td>
                      <b-td style="border: none;">
                        <input
                          v-model="text7"
                          type="text"
                          class="form-control"
                          disabled
                        />
                      </b-td>
                    </b-tr>
                    <span v-if="index === 4 || index === 5" class="font-weight-bold" :style="'fontSize: 13px'">
                      Limited to increments of +/-0.25, within the start and end numbers.
                    </span>
                    <span v-if="index === 6" class="font-weight-bold" :style="'fontSize: 13px'">
                      Whole numbers only, within the start and end numbers.
                    </span>
                    <span v-if="index === 7" class="font-weight-bold" :style="'fontSize: 13px'">
                      Limited to increments of +0.25, within the start and end numbers.
                    </span>
                  </b-td>
                </b-tr>
                <!--- -->
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
    import { fixHeaderTable, isArray, randomData } from "../../common/CommonUtils";
import CheckBoxEditor from "../questionnaire/CheckBoxEditor";
import EditorNumberAge from "../questionnaire/EditorNumberAge.vue";
import EditorNumberRange from "../questionnaire/EditorNumberRange.vue";
import EditorPrescription from "../questionnaire/EditorPrescription.vue";

const HEADERS_TABLE = [
  { title: "PRESCRIPTION", style: "width: 20%", class: "" },
  { title: "UNITS", style: "width: 25%", class: "" },
  { title: "OPTIONS", style: "width: 55%", class: "" },
];
export default {
  components: {
    EditorPrescription,
    EditorNumberRange,
    CheckBoxEditor,
    EditorNumberAge,
  },
  data() {
    this.$store.state.survey.isSurveyEditing = false;
    return {
      header: HEADERS_TABLE,
      curStyle: "color: black",
      isAdditionalContent: false,
      checkboxValue: false,
      checkboxLabelText: '',
      text7: '',
      text5: '',
      text4: '',
      text3: '',
    };
  },
  computed: {
    prescription() {
      return this.$store.state.questionnaire.prescription.prescriptionData;
    },
    isEditContent() {
      this.checkCheckboxSegment();
      return this.$store.state.questionnaire.prescription.editStatus
        ? "edit-th-content"
        : "normal-th-content";
    },
  },
  watch: {
    prescription: {
      deep: true,
      handler: function (data) {
        console.log(data);
        if (data[1] != undefined) {
          this.text7 = data[1].defaults[7].data != '0.00' ? Number(data[1].defaults[7].data).toFixed(2): '';
          this.text5 = data[1].defaults[5].data != '0.00' ? Number(data[1].defaults[5].data).toFixed(2): '';
          this.text4 = data[1].defaults[4].data != '0.00' ? Number(data[1].defaults[4].data).toFixed(2): '';
          this.text3 = data[1].defaults[3].data != '0.00' ? Number(data[1].defaults[3].data) : '';
        }
      }
    },
    checkboxValue: function () {
      if (this.isAdditionalContent) {
        const {text} = this.extractValue(this.mutateValue, !this.checkboxValue)
        this.mutateValue = `${text}{${this.checkboxValue.toString()}}${this.checkboxLabelText}`
        this.focusOut()
      }
    }
  },
  updated() {
    fixHeaderTable(
      "table-edit",
      "header-nav",
      this.$store.state.questionnaire.prescription.editStatus
    );
  },
  mounted() {
    /*if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      location.reload();
    }*/
  },
  methods: {
    transfer(event) {
      this.checkboxValue = event;
      const {text} = this.extractValue(this.$store.state.questionnaire.prescription.prescriptionData[1].options[6].data, !this.checkboxValue)
      this.mutateValue = `${text}`
      if (this.checkboxValue) {
          this.mutateValue = this.mutateValue + '{true} MANDATORY';
      } else {
          this.mutateValue = this.mutateValue + '{false} MANDATORY';
      }
      this.$store.state.questionnaire.prescription.prescriptionData[1].options[6].data = this.mutateValue;
    },
    extractValue(text, bool = null) {
      const splitSeg = bool !== null ? bool : this.checkboxValue.toString()
      const splitValue = text.split(`{${splitSeg}}`)
      return {
        text: splitValue[0],
        label: splitValue[1] || ''
      }
    },
    random() {
      return randomData(10);
    },
    isArray(data) {
      return isArray(data);
    },
    checkCheckboxSegment() {
      if (!this.$store.state.questionnaire.prescription.prescriptionData[1].options[6].data.includes('{true}') && !this.$store.state.questionnaire.prescription.prescriptionData[1].options[6].data.includes('{false}')) {
          return false;
      }
      this.checkboxValue = this.$store.state.questionnaire.prescription.prescriptionData[1].options[6].data.includes('{true}');
      this.isAdditionalContent = true;
      const {label} = this.extractValue(this.$store.state.questionnaire.prescription.prescriptionData[1].options[6].data);
      this.checkboxLabelText = label;
    }
  },
};
</script>

<style scoped>
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: touch;
}

.prescription-td {
  border-top: none;
}

th {
  position: sticky !important;
  top: 0 !important; /* Don't forget this, required for the stickiness */
  position: -webkit-sticky;
}
td {
  word-break: break-word;
}
.additional-container {
  display: flex;
}
label {
  white-space: pre-wrap;
}
</style>
