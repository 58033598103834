<template>
  <div>
    <div v-if="!isEdit">
      <label> {{ value }}</label>
    </div>
    <div v-if="isEdit && isIndex != 6 && isIndex != 0 && isIndex != 1" style="font-weight: bold">
      <label> Always Mandatory</label>
    </div>
    <div v-if="isEdit && isIndex != 0 && isIndex != 1">
      <div class="border">
        <div class="form-group row">
          <label for="startNumber" class="col-sm-5 col-form-label">Start number</label>
          <div class="col-sm-5">
            <EditorNumber
              v-if="isIndex != 3 && isIndex != 6"
              v-model="start"
              :step=".25"
              :max="20"
              :min="-20"
              :is-edit="true"
              :is-show-value="false"
              :is-index="isIndex"
              :is-start="true"
              :start="Number(start)"
              :end="Number(end)"
              @focusout.native="focusOut()"
            />
            <EditorNumber
              v-if="isIndex == 3"
              v-model="start"
              :step="1"
              :max="200"
              :min="0"
              :is-edit="true"
              :is-show-value="false"
              :is-index="isIndex"
              :is-start="true"
              :start="Number(start)"
              :end="Number(end)"
              @focusout.native="focusOut()"
            />
            <EditorNumber
              v-if="isIndex == 6"
              v-model="start"
              :step="1"
              :max="180"
              :min="0"
              :is-edit="true"
              :is-show-value="false"
              :is-index="isIndex"
              :is-start="true"
              :start="Number(start)"
              :end="Number(end)"
              @focusout.native="focusOut()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="endNumber" class="col-sm-5 col-form-label">End number</label>
          <div class="col-sm-5">
            <EditorNumber
              v-if="isIndex != 3 && isIndex != 6"
              v-model="end"
              :step=".25"
              :max="20"
              :min="-20"
              :is-edit="true"
              :is-show-value="false"
              :is-index="isIndex"
              :is-end="true"
              :start="Number(start)"
              :end="Number(end)"
              @focusout.native="focusOut()"
            />
            <EditorNumber
              v-if="isIndex == 3"
              v-model="end"
              :step="1"
              :max="200"
              :min="0"
              :is-edit="true"
              :is-show-value="false"
              :is-index="isIndex"
              :is-end="true"
              :start="Number(start)"
              :end="Number(end)"
              @focusout.native="focusOut()"
            />
            <EditorNumber
              v-if="isIndex == 6"
              v-model="end"
              :step="1"
              :max="180"
              :min="0"
              :is-edit="true"
              :is-show-value="false"
              :is-index="isIndex"
              :is-end="true"
              :start="Number(start)"
              :end="Number(end)"
              @focusout.native="focusOut()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="incrementValue" class="col-sm-5 col-form-label">Default value</label>
          <div class="col-sm-5">
            <EditorNumber
              v-if="isIndex != 3 && isIndex != 6"
              v-model="incre"
              :step=".25"
              :is-show-value="false"
              :is-edit="true"
              :is-index="isIndex"
              :max="Number(end)"
              :min="Number(start)"
              @focusout.native="focusOut()"
            />
            <EditorNumber
              v-if="isIndex == 3 || isIndex == 6"
              v-model="incre"
              :step="1"
              :is-show-value="false"
              :is-edit="true"
              :is-index="isIndex"
              :max="Number(end)"
              :min="Number(start)"
              @focusout.native="focusOut()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from "lodash";
import { isEmpty, isRealNumber, range, rangeEditNumber } from "../../common/CommonUtils";
import EditorNumber from "../questionnaire/EditorNumber.vue";

export default {
  name: "EditorNumberRange",
  components: { EditorNumber },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isIndex: {
      type: Number,
      default: 0,
    },
    isMandatory: {
      type: Boolean,
      default: false,
    },
    defaultData: {
      type: String,
      default: "",
    },
    placeholderText: {
      type: String,
      default: "",
    },
  },
  data() {
    let transValue = this.value.split(/\s*,\s*/);
    // eslint-disable-next-line vue/no-mutating-props
    this.value = isEmpty(this.value) ? "-" : this.value;
    if(this.isIndex != 3 && this.isIndex != 6 && transValue.length > 0 && !isEmpty(this.value)) {
      // eslint-disable-next-line vue/no-mutating-props
      this.value = transValue.map(value => {
        if(Number(value) > 0 && !value.includes("+")) return "+"+value;
        return value;
      }).toString();
    }
    // let defaultZero = 0;
    if (this.isIndex == 3){
      this.incre = this.defaultData == '0.00' ? '' : this.defaultData;
    }
    if (this.isIndex == 4 || this.isIndex == 5 || this.isIndex == 7){
        this.incre = this.defaultData == '0.00' ? '' : Number(this.defaultData).toFixed(2);
    }
    if (this.isIndex == 6){
        this.incre = (this.defaultData == null || this.defaultData == '') ? '' : this.defaultData;
    }
    return {
      start: !isRealNumber(transValue[0]) ? 0 : transValue[0],
      end: !isRealNumber(transValue[transValue.length - 1]) ? 0 : transValue[transValue.length - 1],
    };
  },
  computed: {
  
  },
  watch: {
  
  },
  mounted() {
  
  },
  updated() {
    let transValue = this.value.split(/\s*,\s*/);
    // eslint-disable-next-line vue/no-mutating-props
    this.value = isEmpty(this.value) ? "-" : this.value;
    if(this.isIndex != 3 && this.isIndex != 6 && transValue.length > 0 && !isEmpty(this.value)) {
      // eslint-disable-next-line vue/no-mutating-props
      this.value = transValue.map(value => {
        if(Number(value) > 0 && !value.includes("+")) return "+"+value;
        return value;
      }).toString();
    }
  },
  methods: {
    focusOut() {
      let value = "-";
      const start = +this.start;
      const end = +this.end;
      let incre = 1;
      const index = this.isIndex;
      const mandatory = this.isMandatory;
      if (index == 4 || index == 5 || index == 7) {
          incre = 0.25;
      }
      if (start < end && incre > 0 && incre <= Math.abs(end - start)) {
        if (this.isIndex == 6 || this.isIndex == 3) {
          value = range(start, end, incre).join(",");
        } else {
          value = rangeEditNumber(start, end, incre).join(",");
        }
      }
      if (index == 7) {
          this.$store.state.questionnaire.prescription.prescriptionData[1].options[7].data = value;
          this.$store.state.questionnaire.prescription.prescriptionData[1].defaults[7].data = this.incre;
      }
      if (index == 6) {
          if (mandatory) {
              value = value + ',{true} MANDATORY';
          } else if (!mandatory) {
              value = value + ',{false} MANDATORY';
          }
          this.$store.state.questionnaire.prescription.prescriptionData[1].options[6].data = value;
          this.$store.state.questionnaire.prescription.prescriptionData[1].defaults[6].data = this.incre;
      }
      if (index == 5) {
          this.$store.state.questionnaire.prescription.prescriptionData[1].options[5].data = value;
          this.$store.state.questionnaire.prescription.prescriptionData[1].defaults[5].data = this.incre;
      }
      if (index == 4) {
          this.$store.state.questionnaire.prescription.prescriptionData[1].options[4].data = value;
          this.$store.state.questionnaire.prescription.prescriptionData[1].defaults[4].data = this.incre;
      }
      if (index == 3) {
          this.$store.state.questionnaire.prescription.prescriptionData[1].options[3].data = value;
          this.$store.state.questionnaire.prescription.prescriptionData[1].defaults[3].data = this.incre;
      }
      if (index == 1) {
          this.$store.state.questionnaire.prescription.prescriptionData[1].options[1].data = value;
      }
      if (index == 0) {
          this.$store.state.questionnaire.prescription.prescriptionData[1].options[0].data = value;
      }
      this.$emit("input", value);
    },
    handleInput() {
      let value = "-";
      const start = +this.start;
      const end = +this.end;
      let incre = 1;
      const index = this.isIndex;
      if (index == 4 || index == 5 || index == 7) {
          incre = 0.25;
      }
      if (start < end && incre > 0 && incre <= Math.abs(end - start)) {
        value = range(start, end, incre).join(",");
      }
      this.$emit("input", value);
    },
  },
};
</script>
<style scoped>
label {
  white-space: pre-wrap;
}

.border {
  border: 1px solid #d8dbe0;
  border-color: #d8dbe0;
  padding-left: 3px;
  padding-top: 5px;
  width: 100%;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 4px;
}
</style>
