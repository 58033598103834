<template>
  <div>
    <div v-if="isDisabled">
      <b-form-checkbox
        :id="id"
        value="false"
        disabled
        :name="id"
      />
    </div>
    <div v-else-if="isEdit">
      <div>
        <b-form-checkbox
          :id="id"
          v-model="applyValue"
          :name="id"
          @click.native="clickData()"
        />
      </div>
    </div>
    <div v-else>
      <b-form-checkbox
        :id="id"
        v-model="applyValue"
        disabled
        :name="id"
      />
    </div>
  </div>
</template>

<script>
// import $ from 'jquery'
// import _ from 'lodash'

import { isBoolean, randomData } from "../../common/CommonUtils";

export default {
  name: "CheckBoxEditor",
  props: {
    value: {
      type: [Boolean, String],
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      id: null,
      mutateValue: isBoolean(this.value)
        ? this.value === "true"
          ? true
          : this.value === "false"
          ? false
          : this.value
        : true,
    };
  },
  computed: {
    applyValue: {
      get: function() {
        return this.mutateValue;
      },
      set: function(newValue) {
        this.mutateValue = newValue;
      },
    },
  },
  mounted() {
    this.id = `checkBoxEditor-${this.random()}`
  },
  methods: {
    clickData() {
      this.$emit("transfer", !this.applyValue);
    },
    random() {
      return randomData(5, 2);
    },
  },
};
</script>
<style scoped></style>
