<template>
  <div>
    <div v-if="!isEdit">
      <label> {{ value }}</label>
    </div>
    <div v-if="isEdit" style="font-weight: bold">
      <label> Always Mandatory</label>
    </div>
    <div v-if="isEdit">
      <div class="border">
        <div class="form-group row">
          <label for="startNumber" class="col-sm-5 col-form-label">Start number</label>
          <div class="col-sm-5">
            <EditorNumber
              v-model="start"
              :max="200"
              :min="0"
              :is-edit="true"
              :is-show-value="false"
              :is-index="isIndex"
              :is-start="true"
              :start="Number(start)"
              :end="Number(end)"
              @focusout.native="focusOut()"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="endNumber" class="col-sm-5 col-form-label">End number</label>
          <div class="col-sm-5">
            <EditorNumber
              v-model="end"
              :max="200"
              :min="0"
              :is-edit="true"
              :is-show-value="false"
              :is-index="isIndex"
              :is-end="true"
              :start="Number(start)"
              :end="Number(end)"
              @focusout.native="focusOut()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from "lodash";
import { isEmpty, isRealNumber, range } from "../../common/CommonUtils";
import EditorNumber from "../questionnaire/EditorNumber.vue";

export default {
  name: "EditorNumberAge",
  components: { EditorNumber },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: "-",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    let transValue = this.value.split(/\s*,\s*/);
    // eslint-disable-next-line vue/no-mutating-props
    this.value = isEmpty(this.value) ? "-" : this.value;
    return {
      start: !isRealNumber(transValue[0]) ? 0 : transValue[0],
      end: !isRealNumber(transValue[transValue.length - 1]) ? 0 : transValue[transValue.length - 1],
      incre: transValue.length > 1 ? transValue[1] - transValue[0] : 0,
      editValue: this.value,
      originData: this.value,
      isAdditionalContent: false,
      checkboxValue: false,
      checkboxLabelText: null
    };
  },
  computed: {
      applyValue: {
          get: function () {
              if (this.isAdditionalContent) {
                  const {text} = this.extractValue(this.mutateValue)
                  return text
              } else return this.mutateValue;
          },
          set: function (newValue) {
              if (this.isAdditionalContent) {
                  this.mutateValue = `${newValue}{${this.checkboxValue.toString()}}${this.checkboxLabelText}`
              } else {
                  this.mutateValue = newValue;
              }
              this.presentValue = this.createPresentValue(newValue)
          },
      },
  },
  watch: {
      checkboxValue: function () {
          if (this.isAdditionalContent) {
              const {text} = this.extractValue(this.mutateValue, !this.checkboxValue)
              this.mutateValue = `${text}{${this.checkboxValue.toString()}}${this.checkboxLabelText}`
              this.focusOut()
          }
      }
  },
  mounted() {
      this.mutateValue = this.value;
      if (this.checkCheckboxSegment()) {
          const {text} = this.extractValue(this.value);
          this.presentValue = this.createPresentValue(text)
      } else {
          this.presentValue = this.createPresentValue(this.value);
      }
  },
  updated() {
    // eslint-disable-next-line vue/no-mutating-props
    this.value = isEmpty(this.value) ? "-" : this.value;
  },
  methods: {
    transfer(event) {
      this.checkboxValue = event;
    },
    focusOut() {
      let value = "-";
      const start = +this.start;
      const end = +this.end;
      const incre = 1;
      if (start < end && incre > 0 && incre <= Math.abs(end - start)) {
        value = range(start, end, incre).join(",");
      }
      this.$store.state.questionnaire.prescription.prescriptionData[1].options[2].data = value;
      this.$emit("input", value);
    },
    createPresentValue(text) {
        return /\r|\n/.test(text) ? text.split('\n') : text
    },
    extractValue(text, bool = null) {
        const splitSeg = bool !== null ? bool : this.checkboxValue.toString()
        const splitValue = text.split(`{${splitSeg}}`)
        return {
            text: splitValue[0],
            label: splitValue[1] || ''
        }
    },
    handleInput() {
      let value = "-";
      const start = +this.start;
      const end = +this.end;
      const incre = +this.incre.toFixed(2);
      if (start < end && incre > 0 && incre <= Math.abs(end - start)) {
        value = range(start, end, incre).join(",");
      }
      this.$emit("input", value);
    },
    checkCheckboxSegment() {
      if (!this.value.includes('{true}') && !this.value.includes('{false}')) {
        return false;
      }
      this.isAdditionalContent = true;
      this.checkboxValue = this.value.includes('{true}');
      const {label} = this.extractValue(this.value);
      this.checkboxLabelText = label;
      return true;
    }
  },
};
</script>
<style scoped>
label {
  white-space: pre-wrap;
}

.border {
  border: 1px solid #d8dbe0;
  border-color: #d8dbe0;
  padding-left: 3px;
  padding-top: 5px;
  width: 100%;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 4px;
}
.additional-container {
  display: flex;
}
</style>
